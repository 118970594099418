<template>
  <NavQuestion
    :heading="heading"
    :number="'2'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'Celebration of my life'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-celebration.png'"
        :selected="funeral.funeral_type_celebration"
        @selected="
          save(
            {
              funeral_type_celebration: !funeral.funeral_type_celebration
            },
            false,
            true
          )
        "
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'Simple and respectful'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-modest.png'"
        :selected="funeral.funeral_type_simple"
        @selected="
          save(
            { funeral_type_simple: !funeral.funeral_type_simple },
            false,
            true
          )
        "
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'c'"
        :option="'Green eco friendly'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-simple.png'"
        :selected="funeral.funeral_type_green"
        @selected="
          save({ funeral_type_green: !funeral.funeral_type_green }, false, true)
        "
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'d'"
        :option="'Grand send-off'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-grand.png'"
        :selected="funeral.funeral_type_grand"
        @selected="
          save({ funeral_type_grand: !funeral.funeral_type_grand }, false, true)
        "
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'e'"
        :option="'Non religious'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-non-religious.png'"
        :selected="funeral.funeral_type_non_religious"
        @selected="
          save(
            {
              funeral_type_non_religious: !funeral.funeral_type_non_religious
            },
            false,
            true
          )
        "
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'f'"
        :option="'No preference'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-preference.png'"
        :selected="funeral.funeral_type_no_preference"
        @selected="
          save(
            {
              funeral_type_no_preference: !funeral.funeral_type_no_preference
            },
            true,
            false
          )
        "
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/funeral/type'"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'

export default {
  name: 'FuneralStyle',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  computed: {
    funeral() {
      return this.$store.getters.funeral
    },
    isValidated() {
      if (
        this.funeral.funeral_type_non_religious ||
        this.funeral.funeral_type_grand ||
        this.funeral.funeral_type_green ||
        this.funeral.funeral_type_simple ||
        this.funeral.funeral_type_celebration ||
        this.funeral.funeral_type_no_preference
      )
        return true
      return false
    },
    heading() {
      return 'What type of service would you prefer?'
    },
    subHeading() {
      return 'When your executors come to organise your funeral, information about your wishes can be invaluable.'
    },
    forwardTo() {
      return '/funeral/organ_donor'
    }
  },
  methods: {
    save(ob, clearAll = false, clearNo = false) {
      if (this.funeral) {
        if (clearAll) {
          this.$store.commit('funeral', {
            funeral_type_non_religious: false,
            funeral_type_grand: false,
            funeral_type_green: false,
            funeral_type_simple: false,
            funeral_type_celebration: false
          })
        }
        if (clearNo) {
          this.$store.commit('funeral', {
            funeral_type_no_preference: false
          })
        }
        this.$store.commit('funeral', ob)
      }
    }
  }
}
</script>
