var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NavQuestion',{attrs:{"heading":_vm.heading,"number":'2',"subHeading":_vm.subHeading,"questionOptions":true},scopedSlots:_vm._u([{key:"contents",fn:function(){return [_c('InputOptionSmall',{attrs:{"letter":'a',"option":'Celebration of my life',"image":'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-celebration.png',"selected":_vm.funeral.funeral_type_celebration},on:{"selected":function($event){return _vm.save(
          {
            funeral_type_celebration: !_vm.funeral.funeral_type_celebration
          },
          false,
          true
        )}}}),_c('InputOptionSmall',{attrs:{"letter":'b',"option":'Simple and respectful',"image":'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-modest.png',"selected":_vm.funeral.funeral_type_simple},on:{"selected":function($event){return _vm.save(
          { funeral_type_simple: !_vm.funeral.funeral_type_simple },
          false,
          true
        )}}}),_c('InputOptionSmall',{attrs:{"letter":'c',"option":'Green eco friendly',"image":'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-simple.png',"selected":_vm.funeral.funeral_type_green},on:{"selected":function($event){return _vm.save({ funeral_type_green: !_vm.funeral.funeral_type_green }, false, true)}}}),_c('InputOptionSmall',{attrs:{"letter":'d',"option":'Grand send-off',"image":'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-grand.png',"selected":_vm.funeral.funeral_type_grand},on:{"selected":function($event){return _vm.save({ funeral_type_grand: !_vm.funeral.funeral_type_grand }, false, true)}}}),_c('InputOptionSmall',{attrs:{"letter":'e',"option":'Non religious',"image":'https://mylastwill.s3.amazonaws.com/static/img/button-non-religious.png',"selected":_vm.funeral.funeral_type_non_religious},on:{"selected":function($event){return _vm.save(
          {
            funeral_type_non_religious: !_vm.funeral.funeral_type_non_religious
          },
          false,
          true
        )}}}),_c('InputOptionSmall',{attrs:{"letter":'f',"option":'No preference',"image":'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-preference.png',"selected":_vm.funeral.funeral_type_no_preference},on:{"selected":function($event){return _vm.save(
          {
            funeral_type_no_preference: !_vm.funeral.funeral_type_no_preference
          },
          true,
          false
        )}}})]},proxy:true},{key:"navButtons",fn:function(){return [_c('NavQuestionButtons',{attrs:{"validated":_vm.isValidated,"forwardText":'Next',"forwardLink":_vm.forwardTo,"backLink":'/funeral/type',"center":false}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }